<template>
  <div class="one_test" v-loading="loading">
    <!-- 选项卡 -->
    <div class="option_box">
      <div class="option_content_box">
        <!-- 题目卡片 -->
        <!-- v-if="index === page" -->
        <div class="option_content">
          <!-- 单选/多选/ -->
          <div class="option_name">
            <!-- <p>{{ qtype }}</p> -->
            <p v-if="data[is_num].qtype === 1">单选题</p>
            <p v-else-if="data[is_num].qtype === 2">配伍题</p>
            <p v-else-if="data[is_num].qtype === 3">材料分析选择题</p>
            <p v-else-if="data[is_num].qtype === 4">多选题</p>
            <p v-else-if="data[is_num].qtype === 5">材料分析题</p>
            <!-- 收藏 -->
            <div style="display: flex; align-items: center">
              <div
                v-if="data[is_num].is_collect == 0"
                class="collect_box"
                @click="collect()"
              >
                <img src="../../assets/img/collect.png" alt="" />
                收藏本题
              </div>
              <div
                v-if="data[is_num].is_collect == 1"
                class="collect_box no_collect"
                @click="delcollect()"
              >
                <img src="../../assets/img/quxiao.png" alt="" />
                取消收藏
              </div>
              <div
                style="
                  width: 1px;
                  height: 17px;
                  background: #e5e5e5;
                  border-radius: 1px;
                  margin: 0 20px;
                "
              ></div>
              <div class="collect_box" @click="errorCorrection(data[is_num])">
                <img src="../../assets/img/jiucuo.png" alt="" />
                试题纠错
              </div>
            </div>

            <!-- item.collect_status == true ||  -->
          </div>
          <Question1
            v-if="data[is_num].qtype == 1"
            :data="data[is_num]"
            :type="1"
            :isReveal="isReveal"
            :is_num="is_num + 1"
            @pick="pick"
          />
          <Question2
            v-if="data[is_num].qtype == 2"
            :data="data[is_num]"
            :is_2_show="is_2_show"
            :type="1"
            :isReveal="isReveal"
            :is_num="is_num + 1"
            @is_Tshow="is_Tshow"
          />
          <Question3
            v-if="data[is_num].qtype == 3"
            :data="data[is_num]"
            :type="1"
            :isReveal="isReveal"
            :is_num="is_num + 1"
          />
          <Question4
            v-if="data[is_num].qtype == 4"
            :data="data[is_num]"
            :type="1"
            :isReveal="isReveal"
            :is_num="is_num + 1"
          />
          <Question5
            v-if="data[is_num].qtype == 5"
            :data="data[is_num]"
            :type="1"
            :isReveal="isReveal"
            :is_num="is_num + 1"
          />
        </div>
        <!-- 上一题/下一题 -->
        <div class="cut_topic">
          <!-- 上一题 -->
          <button class="prev_box" :disabled="prev_disabled" @click="prev()">
            <i class="el-icon-arrow-left"></i>上一题
          </button>
          <div v-if="type != 99" class="look_answer" @click="look_answer()">
            <img
              :src="
                isReveal
                  ? require('../../assets/img/chakan.png')
                  : require('../../assets/img/daan.png')
              "
              alt=""
            />
            <p :class="{ close_light: !isReveal }">
              {{ isReveal ? "查看解析" : "关闭解析" }}
            </p>
          </div>
          <button class="prev_box" :disabled="next_disabled" @click="next()">
            下一题<i class="el-icon-arrow-right" style="margin-left: 30px"></i>
          </button>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <span>
        {{
          type == 1
            ? "您已做完是否重新生成随机练习题？"
            : "本章您已做完所有题目，点击返回章节列表"
        }}</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="back">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 试题纠错弹窗 -->
    <div class="wrongmask_box" v-show="show_wrongmask == true">
      <div class="mask_box">
        <img
          src="../../assets/img/close.png"
          alt=""
          @click="show_wrongmask = false"
        />
        <div class="wrong_content">
          <div class="wrong_title">试题纠错</div>
          <div class="wrong_text">
            非常感谢您向我们提出错误，我们将尽力为您提供最优质的服务
          </div>
          <div class="wrong_text2">错误描述</div>
          <div class="wrong_ipt_box">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              maxlength="300"
              v-model="suggest"
            ></textarea>
            <p>{{ suggest.length }}/300</p>
          </div>
          <div class="submit_box">
            <button type="button" @click="show_wrongmask = false">取消</button>
            <button type="button" @click="submitError">提交纠错</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import Question1 from "../question_1/index.vue";
import Question2 from "../question_2/index.vue";
import Question3 from "../question_3/index.vue";
import Question4 from "../question_4/index.vue";
import Question5 from "../question_5/index.vue";
export default {
  name: "One_test",
  components: {
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
  },
  props: {
    data: Array,
    type: Number,
    dataCode: Boolean,
    moredata: {
      type: Function,
      default: function () {
        return false;
      }
    },
    curpage: {
      type: Number,
      default: 1
    },
    totalpage: {
      type: Number,
      default: 1
    },
    totalitem: {
      type: Number,
      default: -1
    },
    isReveal1:{
      default:true,
    }// 查看解析状态
  },
  data() {
    return {
      topic_id: "", // 题目id
      topic_type: "", // 题目类型
      suggest: "",
      show_wrongmask: false,
      dialogVisible: false,
      is_num: 0, //当前是第几题
      // moredata: () => false,
      prev_disabled: false, //是否禁用上一题按钮
      next_disabled: false, //是否禁用下一题按钮
      isReveal: true, // 查看解析状态
      check_all: [], //多选题临时存储
      is_2_show: false,
      loading: false
    };
  },
  watch: {
    data(val) {
      //   console.log(val);
    },
    is_num(val) {
      if (val == 0) {
        this.prev_disabled = true;
      } else {
        this.prev_disabled = false;
      }
      if (val == this.data.length) {
        this.next_disabled = true;
      } else {
        this.next_disabled = false;
      }

      if (this.data[val].qtype == 2) {
        let a = this.data[val].qu_list.find((el) => {
          return el.user_answer == "";
        });
        if (a) {
          this.is_2_show = false;
        } else {
          this.is_2_show = true;
        }
        // console.log(this.is_2_show);
      }

      // console.log(val);
      this.check_all = [];
      //多选
      // if (this.data[val].qtype == 4) {
      //   if (this.data[val].user_answer == "") {
      //     this.isReveal = true;
      //   } else {
      //     this.isReveal = false;
      //   }
      // }
      // 配伍
      // if (this.data[val].qtype == 2) {
      //   this.data[val].qu_list.find((el) => {
      //     if (el.user_answer == "") {
      //       this.is_2_show = false;
      //     } else {
      //       this.is_2_show = true;
      //     }
      //   });
      // }
      // 材料
      // if (this.data[val].qtype == 3) {
      //   this.data[val].qu_list.forEach((el) => {
      //     el.check_all = [];
      //   });
      // }
      this.$emit("isNum", this.is_num);
    },
  },
  computed: {
    isLastPage() {
      let _this = this;
      return _this.curpage >= _this.totalpage
    },
    isLastItem() {
      let _this = this;
      return (_this.is_num+1) >= (_this.totalitem === -1 ? _this.data.length : _this.totalitem)
    }
  },
  created() {
    this.isReveal = this.isReveal1
  },
  methods: {
    is_Tshow(val) {
      this.is_2_show = val;
    },
    //选择触发
    pick(val) {
      console.log(val);
      console.log(this.data);
    },
    getMoreData() {
      return this.moredata()
    },
    // 返回上一页
    back() {
      if (this.type == 1) {
        this.$emit("again", true);
        this.dialogVisible = false;
        this.is_num = 0;
      }
      if (this.type == 2) {
        this.$router.go(-1);
      }
    },
    // 收藏试题
    collect() {
      // console.log("收藏el====", el);
      if (this.data[this.is_num].is_collect == 0) {
        let data = {
          chapter_category: this.data[this.is_num].two_chaptercate_id,
          ex_id: this.data[this.is_num].id,
          qtype: this.data[this.is_num].qtype,
          three_category_id:this.data[this.is_num].chapter_category_id
        };
        api.add_collect(data).then((res) => {
          // console.log("收藏", res);
          if (parseInt(res.data.code) == 1) {
            // // this.my_like = true;
            this.data[this.is_num].is_collect = 1;
            this.$message.success({
              duration: 2000,
              message: "收藏成功!",
            });
          }
        });
      } else {
        // this.my_like = false;
        this.$message.error({
          duration: 2000,
          message: "本题已收藏!",
        });
      }
    },
    // 取消收藏
    delcollect() {
      if (this.data[this.is_num].is_collect == 1) {
        let data = {
          chapter_category: this.data[this.is_num].two_chaptercate_id,
          ex_id: this.data[this.is_num].id,
          qtype: this.data[this.is_num].qtype,
        };
        api.del_collect(data).then((res) => {
          // console.log("收藏", res);
          if (parseInt(res.data.code) == 1) {
            // // this.my_like = true;
            this.data[this.is_num].is_collect = 0;
            this.$message.success({
              duration: 2000,
              message: "取消收藏成功!",
            });
          }
        });
      } else {
        // this.my_like = false;
        this.$message.error({
          duration: 2000,
          message: "本题未收藏!",
        });
      }
    },
    //上一题 下一题
    prev() {
      console.log(this.is_num);
      if (this.is_num == 0) {
        return;
      } else {
        this.is_num--;
      }
    },
    async next() {
      // 数据触底后获取下一页数据，阻塞等待数据请求成功后再下一步处理
      if (!this.isLastPage && Number(this.is_num) == this.data.length - 1) {
        let tmp_next_disabled = this.next_disabled
        this.next_disabled = true
        this.loading = true
        await this.getMoreData()
        setTimeout(() => {
          this.next_disabled = tmp_next_disabled
          this.loading = false
        }, 200)
      }

      if (this.isLastItem) {
        this.dialogVisible = true;
      } else {
        this.is_num++;
      }
    },
    //查看关闭解析
    look_answer() {
      this.isReveal = !this.isReveal;
    },
    // 试题纠错弹窗
    errorCorrection(item) {
      console.log(item);
      this.show_wrongmask = true; // 显示纠错弹窗
      this.topic_id = item.id;
      this.topic_type = item.qtype;
      // console.log("item=====", item);
    },
    // 提交纠错
    submitError() {
      if (this.suggest.length == 0) {
        this.$message.error({
          message: "描述不能为空!",
          duration: 2000,
        });
        return false;
      } else {
        let data = {
          chapter_category: this.$route.params.chapter_id, // 章节id
          ex_id: this.topic_id, // 题目id
          qtype: this.topic_type, // 题目类型
          suggest: this.suggest, // 纠错描述
        };
        api.correction_add(this.qs.stringify(data)).then((res) => {
          if (res.data.code == 1) {
            this.$message.success({
              message: res.data.msg,
              duration: 2000,
            });
            this.show_wrongmask = false; // 关闭纠错弹窗
          }
          // console.log("纠错======", res);
        });
      }
    },
    //  // 获取做题数上报接口
    getTopicNumber() {
      // console.log(this.data);
      let a = 0;
      let b = 0;
      let data = [];
      this.data.forEach((x) => {
        switch (x.qtype) {
          case 1:
            if (x.is_YN) {
              a++;
              data.push({
                two_chapcate_id: x.two_chaptercate_id,
                three_chapcate_id: x.chapter_category_id,
              });

              if (x.is_YN == 2) {
                b++;
              }
            }
            break;
          case 2:
            x.qu_list.forEach((c) => {
              if (x.is_YN) {
                a++;
                data.push({
                  two_chapcate_id: x.two_chaptercate_id,
                  three_chapcate_id: x.chapter_category_id,
                });

                if (c.is_YN == 2) {
                  b++;
                }
              }
            });
            break;
          case 3:
            x.qu_list.forEach((c) => {
              if (x.is_YN) {
                a++;
                data.push({
                  two_chapcate_id: x.two_chaptercate_id,
                  three_chapcate_id: x.chapter_category_id,
                });

                if (c.is_YN == 2) {
                  b++;
                }
              }
            });
            break;
          case 4:
            if (x.is_YN) {
              a++;
              data.push({
                two_chapcate_id: x.two_chaptercate_id,
                three_chapcate_id: x.chapter_category_id,
              });
              if (x.is_YN == 2) {
                b++;
              }
            }
            break;
        }
      });
      let params = [];
      data.forEach((e) => {
        (e.total_count = a), (e.err_count = b);
        if (params.length <= 0) {
          return params.push(e);
        }
        for (let i = 0; i < params.length; i++) {
          if (
            params[i].two_chapcate_id != e.two_chapcate_id &&
            params[i].three_chapcate_id != e.three_chapcate_id
          ) {
            params.push(e);
          }
        }
      });
      if (params.length > 0) {
        let a = "";
        for (let index = 0; index < params.length; index++) {
          if (a == "") {
            a = JSON.stringify(params[index]);
          } else {
            a = a + "|" + JSON.stringify(params[index]);
          }
        }
        params = a;
        api.question_count(params).then((res) => {
          // console.log("做题数", res);
        });
      }
    },
  },
  beforeDestroy() {
    this.getTopicNumber();
  },
};
</script>

<style lang="scss" scoped>
// 选项卡
::v-deep .option_box {
  width: 100%;
  // height: 1000px;
  // background: pink;
  .option_content_box {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    // padding: 0 40px;
    // 题目
    .option_content {
      width: 1120px;
      min-height: 398px;
      background: #ffffff;
      border-radius: 12px;
      padding: 0 40px;
      margin: 0 0 90px;
      // 单选/多选
      .option_name {
        // width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 30px 0 34px;
        padding: 0 15px;
        p {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
        }
        // 收藏
        .collect_box {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          img {
            width: 16px;
            height: 14px;
            margin-right: 4px;
          }
        }
        // 取消收藏
        .no_collect {
          color: #3e7eff;
        }
      }
      // 配伍题问题
      .topic_box {
        .option_box {
          display: flex;
          margin-top: -17px;
          li {
            display: flex;
            margin-right: 60px;
            p {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
        }
      }
      // 问题标题
      .question_title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
        // margin-bottom: 25px;
      }
      // 材料分析题问题
      .material_problem_box {
        // 简答题文案
        .brief_answer {
          margin-top: 29px;
          display: flex;
          align-items: center;
          p {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
          }
          span {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666;
          }
        }
        // 问题
        .problem_content_box {
          margin: 37px 0 69px;
          div {
            p {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
        }
      }
      // 单选选项组
      .radio_box {
        ul {
          .dis {
            pointer-events: none;
          }
          li {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            .left {
              display: flex;
              align-items: center;
              width: 100%;
              max-height: 300px;
              overflow: hidden;
              padding: 15px;
              cursor: pointer;
              button {
                min-width: 26px;
                height: 26px;
                border: 1px solid #989898;
                border-radius: 50%;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
                text-align: center;
                // line-height: 26px;
                margin-right: 14px;
                background: #fff;
              }
              p {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
              img {
                max-width: 800px;
                max-height: 300px;
                // margin-bottom: 9px;
              }
              .yes {
                background: #72b852;
                color: #ffffff;
                border: none;
              }
              .wrong {
                background: #ee3d2b;
                color: #ffffff;
                border: none;
              }
            }
            .notclick {
              pointer-events: none;
            }
            .left:hover {
              background: #f7f9ff;
            }
            .right {
              position: absolute;
              right: 15px;
              bottom: 20px;
              display: flex;
              // align-items: center;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3e7eff;
              margin-left: 51px;
              // margin-bottom: 9px;
              line-height: 16px;
              img {
                width: 16px;
                height: 16px;
                margin-right: 9px;
              }
            }
          }
          li:last-child {
            margin-bottom: 0;
          }
        }
      }
      // 多选题组
      .check_box {
        ul {
          li {
            .left {
              button {
                border-radius: 4px;
                cursor: pointer;
              }
              .check {
                background: #3e7eff;
                color: #fff;
                border: none;
              }
            }
          }
        }
      }

      // 配伍题/案例选择
      .compatibility_box {
        margin-top: 39px;
        /deep/.el-pagination {
          padding: 0;
          .el-pager {
            .number {
              width: 60px;
              height: 30px;
              border: 2px solid #78a4ff;
              border-radius: 0;
              margin: 0;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #729cff;
              background: #fff;
            }
            .active {
              border: none;
              background: #3e7eff;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
        // 小题
        .small_topic_box {
          width: 1102px;
          min-height: 100px;
          border: 2px solid #eeeeee;
          padding-left: 18px;
          .topic_title {
            display: flex;
            margin: 30px 0;
          }
        }
      }
    }
    //  上一题/下一题
    .cut_topic {
      position: fixed;
      bottom: 0;
      width: 906px;
      height: 66px;
      background: #ffffff;
      padding: 0 147px;
      box-shadow: 0px -4px 20px 0px rgba(0, 13, 40, 0.03);
      border-radius: 12px 12px 0px 0px;
      z-index: 1;
      cursor: pointer;
      // margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-left: -40px;
      .prev_box {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
        cursor: pointer;
        background: #fff;
        i {
          margin-right: 30px;
          color: #666;
        }
      }
      .look_answer {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3e7eff;
        display: flex;
        align-items: center;
        img {
          width: 22px;
          height: 26px;
          margin-right: 10px;
        }
        .close_light {
          color: #222;
        }
      }
    }
  }
}
::v-deep .option_box .option_content_box .option_content .radio_box_p ul {
  display: flex;
}
::v-deep
  .option_box
  .option_content_box
  .option_content
  .radio_box_p
  ul
  li
  .left {
  display: flex;
  align-items: center;
  width: 973px;
  max-height: 300px;
  overflow: hidden;
  padding-left: 6px;
  cursor: pointer;
}
::v-deep
  .option_box
  .option_content_box
  .option_content
  .radio_box_p
  ul
  li
  .left
  button {
  min-width: 26px;
  height: 26px;
  border: 1px solid #989898;
  border-radius: 50%;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  text-align: center;
  margin-right: 14px;
  background: #fff;
}
::v-deep
  .option_box
  .option_content_box
  .option_content
  .radio_box_p
  ul
  li
  .left
  p {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
}
//配伍题问题
::v-deep .radio_c_c .c_box {
  padding: 30px 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  background: #f8f8f8;
}
::v-deep .radio_c_c .analysis_box {
  margin: 0;
}
::v-deep .radio_c_c .analysis_box,
::v-deep .radio_c_c .top {
  background: transparent !important;
}
::v-deep .radio_c_c ul {
  display: flex;
  margin: 30px 0 0;
}
::v-deep .radio_c_c ul .red {
  color: #ee3d2b;
}
::v-deep .radio_c_c ul .red i {
  color: #fff !important;
  background: #ee3d2b !important;
  border: none !important;
}
::v-deep .radio_c_c ul .yes {
  color: #72b852;
}
::v-deep .radio_c_c ul .yes i {
  color: #fff !important;
  background: #72b852 !important;
  border: none !important;
}
::v-deep .radio_c_c ul li {
  width: 100px;
  height: 40px;
  align-items: center;
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-right: 36px;
  cursor: pointer;
  font-size: 18px;
}
::v-deep .radio_c_c ul li i {
  width: 22px;
  height: 22px;
  border: 1px solid #989898;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  margin-left: 13px;
  margin-right: 12px;
}
::v-deep .radio_box_pcl {
  width: auto !important;
  border-radius: 15px;
  margin: 30px 0 0 !important;
  padding: 30px 20px;
  overflow: hidden;
}
::v-deep .analysis_box {
  width: auto !important;
}

//   答案解析
::v-deep .radio_box_pcl,
::v-deep .radio_c_c,
::v-deep .analysis_box {
  width: 1120px;
  margin: 54px auto 70px;
  background: #f5f5f5;
  // 单选/多选/配伍/材料选择
  .top {
    height: 60px;
    background: #f5f5f5;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      span {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #72b852;
        img {
          width: 14px;
          height: 14px;
        }
        .dui_icon {
          width: 17px;
          height: 12px;
        }
      }
      span:nth-child(1) {
        margin-right: 45px;
      }
      .user_answer {
        color: #72b852;
      }
      .wrong_answer {
        color: #ee3d2b;
      }
    }
    .right {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
      p {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #777777;
      }
    }
  }
  .down {
    width: 1087px;
    // min-height: 80px;
    background: #f5f5f5;
    border-top: 1px dashed #dddddd;
    margin: auto;
    .down_content {
      display: flex;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      padding: 18px 0;
      div:nth-child(1) {
        min-width: 70px;
        margin-right: 10px;
      }
    }
  }
  // 材料分析题解析
  .material_analysis_box {
    position: relative;
    width: 100%;
    background: #fff;
    overflow: hidden;
    // 答案解析展开状态
    .put_away_box {
      width: 84px;
      height: 36px;
      // background: url();
      background: #ff9302;
      border-radius: 6px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // display: none;
      z-index: -1;
      p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
    // 答案解析展开状态
    .open_box {
      position: absolute;
      top: 1px;
      z-index: 9;
      width: 84px;
      height: 45px;
      background: url("../../assets/img/shouqi1.png") no-repeat;
      background-size: 100px 45px;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .card_box {
      width: 1088px;
      // min-height: 251px;
      padding: 0 16px;
      background: #f5f5f5;
      margin-top: 6px;
      overflow: hidden;
      p {
        margin: 52px 0 19px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
::v-deep .compatimy {
  margin-top: -18px !important;
}
::v-deep .compatimy ul li {
  // margin-bottom: 15px !important;
}
::v-deep .radio_c_c {
  background: #fff !important;
  margin: 0 auto 70px !important;
}
// 单选选项组
::v-deep .radio_box_pcl {
  ul {
    .dis {
      pointer-events: none;
    }
    li {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        padding: 24px;
        cursor: pointer;
        button {
          min-width: 26px;
          height: 26px;
          border: 1px solid #989898;
          border-radius: 50%;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
          text-align: center;
          // line-height: 26px;
          margin-right: 14px;
          background: #fff;
        }
        p {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        img {
          max-width: 800px;
          max-height: 300px;
          margin-bottom: 9px;
        }
        .yes {
          background: #72b852;
          color: #ffffff;
          border: none;
        }
        .wrong {
          background: #ee3d2b;
          color: #ffffff;
          border: none;
        }
      }
      .notclick {
        pointer-events: none;
      }
      .left:hover {
        background: #f7f9ff;
      }
      .right {
        position: absolute;
        right: 15px;
        bottom: 20px;
        display: flex;
        // align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3e7eff;
        margin-left: 51px;
        // margin-bottom: 9px;
        line-height: 16px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 9px;
        }
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
}
::v-deep .d_x_x_z {
  background: #3e7eff !important;
  color: #fff !important;
  border: none !important;
}
::v-deep .YES {
  padding: 8px 50px !important;
  border-radius: 6px !important;
  margin: 30px 0;
}
// 试题纠错弹窗
.wrongmask_box {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  width: 540px;
  height: 423px;
  margin: auto;
  z-index: 999;
  .mask_box {
    position: relative;
    width: 540px;
    height: 423px;
    border: 1px solid #dddddd;
    background: #fff;
    box-shadow: 0px 12px 24px 0px rgba(0, 13, 38, 0.1);
    border-radius: 8px;
    overflow: hidden;
    img {
      position: absolute;
      top: 11px;
      right: 11px;
    }
    .wrong_content {
      width: 460px;
      margin: 31px auto 0;
      .wrong_title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .wrong_text {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        // margin-top: 14px;
        margin: 4px 0 21px;
      }
      .wrong_text2 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 11px;
      }
      .wrong_ipt_box {
        position: relative;
        textarea {
          width: 426px;
          height: 178px;
          border: 1px solid #dddddd;
          border-radius: 2px;
          resize: none;
          padding: 17px 17px 25px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        p {
          position: absolute;
          right: 11px;
          bottom: 11px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .submit_box {
        overflow: hidden;
        width: 200px;
        margin: 20px auto;
        button:nth-child(1) {
          width: 90px;
          height: 30px;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3e7eff;
          border: 1px solid #3e7eff;
          background: #fff;
          margin-right: 20px;
        }
        button:nth-child(2) {
          width: 90px;
          height: 30px;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fff;
          border: 1px solid #3e7eff;
          background: #3e7eff;
        }
      }
    }
  }
}
::v-deep .question_title{
  padding: 0 15px;
}
</style>